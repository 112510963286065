/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { Link } from "gatsby";

import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      site {
        siteMetadata {
          author {
            name
          }
          social {
            twitter,
            email,
            linkedin
          }
        }
      }
    }
  `)

  const { social } = data.site.siteMetadata
  return (
    <div
      style={{
        display: `flex`,
        // justifyContent: `center`,
        alignItems: `center`
      }}
    >
      <div>
        <h2>Hi friends! 👋</h2>
        <p>
          I'm Andrey, and I work as a software engineer in {' '}
          <a href="https://avantstay.com" target="_blank">Avantstay</a>.
        </p>
        <p>
        Here I share all the insights I develop during the process of trying to become better at stuff I enjoy the most: <i>learning</i> and <i>building</i>.
        </p>
        <p>
          <Link to="/now">Right now</Link> I'm on a journey of learning how to build technical products from the ground up and you're welcome to join me on this venture.
        </p>
      </div>
    </div>
  )
}

export default Bio
