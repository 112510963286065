import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { rhythm } from "../utils/typography"

const Social = () => {
  const data = useStaticQuery(graphql`
    query SocialQuery {
      site {
        siteMetadata {
          social {
            twitter,
            email,
            linkedin
          }
        }
      }
    }
  `)

  const { social } = data.site.siteMetadata
  return (
    <div
      style={{
        marginBottom: rhythm(2),
      }}
    >
        <a 
        href={`https://twitter.com/${social.twitter}`}
        target_="blank"
        >
        Talk to me on twitter
        </a>
        {' | '}
        <a href={`mailto:${social.email}`} target="_blank">Email me</a>
        {/* {' | '}
        <a href={social.linkedin} target="_blank">Connect on Linkedin</a> */}
    </div>
  )
}

export default Social
